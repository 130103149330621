import React, { useReducer, useState, useEffect } from "react"
import ImageUploading from "react-images-uploading"
import ColorCombination from "blocks/color-combination"
import Graphic from "blocks/graphic"
import EditableGraphic from "blocks/graphic/editable-graphic"
import "./asset-builder.scss"

import fetch from "fetch"
import { sizes } from "helpers"
import { legalCopy } from "querypieces"

const reducer = (state, action) => {
  switch (action.type) {

    case "setMessage":
      return {
        ...state,
        message: {
          typeHandle: "text",
          text: action.value
        }
      }

    case "setColor":
      return {
        ...state,
        color: action.value
      }

    case "setImage":
      return {
        ...state,
        image: action.value
      }

    case "setSize":
      return {
        ...state,
        size: action.value
      }

    case "setDownload":
      return {
        ...state,
        download: action.value
      }

    case "setUpload":
      return {
        ...state,
        upload: action.value
      }

    case "setLayout":
      return {
        ...state,
        layout: action.value
      }

    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

const purple = "#670a85"

const initialState = {
  message: "",
  image: null,
  color: {
    backgroundColor: purple,
    textColor: "#ffffff",
    accentColor: "#23d29b",
  },
  size: "instagram",
  download: null,
  upload: null
}

const endorsementTxt = "[Partner name here] supports"
const logoText = "YOUR LOGO HERE"

const AssetBuilder = ({ assetBuilder, customizable, graphicType }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    let combo = assetBuilder.filter(graphic => graphic.typeHandle === "colorCombination")

    dispatch({
      type: "setColor",
      value: combo[0].colors[0].colorCombination[0]
    })

    dispatch({
      type: "setMessage",
      value: graphicType === "customGraphic" ? customizable[0].defaultCopy : logoText
    })

    dispatch({
      type: "setLayout",
      value: graphicType === "customGraphic" ? customizable[0].alignment : "default"
    })

    dispatch({
      type: "setImage",
      value: graphicType === "customGraphic" ? customizable[0].defaultImage : null
    })
  }, [customizable, assetBuilder, graphicType])

  let font = (customizable && customizable[0] && customizable[0].fontFamily) ? customizable[0].fontFamily : "Work Sans"

  return <section className="asset-builder">
    {assetBuilder.map(block => {
      if (block.typeHandle === "graphic") {
        return customizable ? <EditableGraphic
          key={block.id}
          fontFamily={font}
          layout={state.layout}
          dispatch={dispatch}
          state={state}
          svg={block.svg[0]}
          graphicType={graphicType}
        /> : <Graphic
            key={block.id}
            svg={block.svg[0]}
            colors={state.color}
            size={state.size}
          />
      } else {
        return <CustomizePanel
          key={block.id}
          colors={block.colors}
          customizable={customizable}
          state={state}
          selected={state.color}
          dispatch={dispatch}
          graphicType={graphicType}
        />
      }
    })}
  </section>
}

const Sizes = ({ current, set }) => {
  return <React.Fragment>
    <h3 className="asset-builder__label">Select Size</h3>
    <div className="sizes-choices">{Object.keys(sizes).map(key => {
      const { size } = sizes[key]
      return <label key={key} className="sizes-choices-item">
        <input type="radio"
          name="Sizes"
          checked={current === key}
          onChange={() => {
            set(key)
          }} />
        <div className={"size-label"}>
          <div className={"size-label-image"}>
            <svg viewBox={`0 0 ${size[0]} ${size[1]}`}>
              <rect width={size[0]} height={size[1]} fill={current === key ? "#dadada" : "#F5F3F1"} />
            </svg>
          </div>

        </div>
        <div className={"size-label-text"}>{key}</div>
      </label>
    })}</div>
  </React.Fragment>
}

const testColorVal = (val, defaultVal) => {
  let fullHex = /^#[0-9A-F]{6}$/i.test(val)
  let shortHex = /^[0-9A-F]{6}$/i.test(val)

  if (fullHex) {
    return val
  } else if (shortHex) {
    return `#${val}`
  } else {
    return defaultVal
  }
}

const CustomColors = ({ colors, set, helperText }) => {
  return <React.Fragment>
    <h3 className="asset-builder__label">Custom Colors</h3>
    <p className="asset-builder__legal">{helperText}</p>
    <div>
      <label>
        <div className="asset-builder__form-label">
          Background
        </div>
        <input
          style={{
            padding: `var(--pad-sm) var(--pad)`
          }}
          type="text"
          name="customColor-background"
          className="asset-builder__input"
          onChange={event => {
            set({
              ...colors,
              backgroundColor: testColorVal(event.target.value, colors.backgroundColor)
            })
          }}
        />
      </label>
      <label>
        <div className="asset-builder__form-label">
          Foreground
        </div>
        <input
          style={{
            padding: `var(--pad-sm) var(--pad)`
          }}
          type="text"
          name="customColor-text"
          className="asset-builder__input"
          onChange={event => {
            set({
              ...colors,
              textColor: testColorVal(event.target.value, colors.textColor)
            })
          }}
        />
      </label>
      {colors.accentColor ? <label>
        <div className="asset-builder__form-label">
          Accent Color
        </div>
        <input
          style={{
            padding: `var(--pad-sm) var(--pad)`
          }}
          type="text"
          name="customColor-text"
          className="asset-builder__input"
          onChange={event => {
            set({
              ...colors,
              accentColor: testColorVal(event.target.value, colors.accentColor)
            })
          }}
        />
      </label>
        : null}
    </div>
  </React.Fragment>
}

const Message = ({ text, setText, messageInputLimit }) => {
  const [inputval, set] = useState("")
  return <div className="message">
    <label>
      <div className="message-input">
        <input
          autoFocus
          placeholder={text}
          type="text"
          value={inputval}
          className="asset-builder__textarea"
          maxLength={messageInputLimit}
          onChange={event => {
            set(event.target.value)
            setText(event.target.value.length ? `${event.target.value}` : text)
          }} />
        <div className={"message-count"}>{`${inputval.length}/${messageInputLimit}`}</div>
      </div>
    </label>
  </div>
}

const UploadImage = ({ set, upload }) => {
  const [error, setError] = useState()

  return <ImageUploading
    onChange={value => {
      set({ type: "setUpload", value })
      setError(null)
    }}
    maxNumber={1}
    maxFileSize={5 * 1024 * 1024} // 5Mb
    onError={(errors, files) =>
      setError(`An error occured. Please try a different file.`)
    }
    acceptType={["svg", "jpg", "png"]}
  >
    {({ imageList, onImageUpload }) => (
      <div>
        {error ? <p className="error-msg">{error}</p> : null}
        <button
          disabled={imageList.length ? true : null}
          className="asset-builder__button"
          onClick={onImageUpload}
        >Browse files</button>
        {imageList.map((image) => (
          <div key={image.key}>
            <div style={{ marginTop: 10 }}>
              <button
                className="asset-builder__button sm"
                onClick={image.onUpdate}>
                Update
            </button>
              <button
                className="asset-builder__button sm"
                onClick={image.onRemove}>
                Remove
            </button>
            </div>
          </div>
        ))}
      </div>
    )}
  </ImageUploading>
}

const Endorsement = ({ graphicType, set, state, helperText }) => {
  const [type, setType] = useState("image")

  return <React.Fragment>
    <h3 className="asset-builder__label">Version</h3>
    <button
      className={`asset-builder__button ${type === "image" ? " selected" : ""}`}
      onClick={() => {
        setType("image")
        set({ type: "setLayout", value: "default" })
        set({ type: "setMessage", value: logoText })
      }}>
      Partner Logo
    </button>
    <button
      className={`asset-builder__button ${type === "text" ? " selected" : ""}`}
      onClick={() => {
        setType("text")
        set({ type: "setLayout", value: "endorsement" })
        set({ type: "setMessage", value: endorsementTxt })
      }}>
      Endorsement
    </button>

    {type === "image" ? <React.Fragment>
      <h3 className="asset-builder__label">Upload yourlogo</h3>
      <p className="asset-builder__legal">{helperText}</p>
      <UploadImage set={set} upload={state.upload} />
    </React.Fragment>
      :
      <React.Fragment>
        <h3 className="asset-builder__label">Message</h3>
        <Message
          messageInputLimit={50}
          text={state.message.text}
          setText={value => {
            set({
              type: "setMessage",
              value
            })
          }} />
      </React.Fragment>
    }
  </React.Fragment>
}

const CustomizePanel = ({
  colors,
  customizable,
  graphicType,
  selected,
  state,
  dispatch
}) => {
  const { data } = fetch(legalCopy)

  if (!colors) return ""

  return <div>
    {graphicType === "logoGraphic" && customizable ? <Endorsement
      graphicType={customizable}
      set={dispatch}
      state={state}
      helperText={data ? data.helperText.helperText[0].logoUpload : null}
    /> : null}


    <h3 className="asset-builder__label">Official Color Combination</h3>
    <div className="color-combination">
      {colors.map(color => <ColorCombination
        key={color.id}
        colorCombination={color.colorCombination}
        setColors={value => {
          dispatch({
            type: "setColor",
            value
          })
        }}
        current={selected} />
      )}
    </div>

    {customizable && graphicType === "customGraphic" ?
      <React.Fragment>
        <h3 className="asset-builder__label">Message</h3>
        <Message
          messageInputLimit={70}
          text={state.message.text}
          setText={value => {
            dispatch({
              type: "setMessage",
              value
            })
          }} />
      </React.Fragment>
      : null}

    {graphicType === "customGraphic" && customizable[0].alignment === "imageAndCopy" ?
      <React.Fragment>
        <h3 className="asset-builder__label">Upload your image</h3>
        <p className="asset-builder__legal">{data ? data.helperText.helperText[0].imageUpload : null}</p>
        <UploadImage set={dispatch} upload={state.upload} />
      </React.Fragment>
      : null}

    {(customizable && graphicType === "logoGraphic") || (typeof customizable === "object" && customizable[0].hasCustomColors) ?
      <CustomColors colors={state.color} set={value => {
        dispatch({
          type: "setColor",
          value
        })
      }}
        helperText={data ? data.helperText.helperText[0].customColor : null}
      />
      : null}

    {graphicType === "customGraphic" ?
      <Sizes
        current={state.size}
        set={value => {
          dispatch({
            type: "setSize",
            value
          })
        }}
      />
      : null}
  </div>
}

export default AssetBuilder
